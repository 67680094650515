import React from 'react';
import { Navigate, Route } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import {
  catalogGraphPlugin,
  CatalogGraphPage
} from '@backstage/plugin-catalog-graph';
import { LighthousePage } from '@backstage/plugin-lighthouse';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';

import {
  createTheme,
  genPageTheme,
  lightTheme,
  shapes,
} from '@backstage/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';

const  microsoftProvider = {
  id: ' microsoft-auth-provider',
  title: 'Microsoft',
  message: 'Sign in using Microsoft',
  apiRef: microsoftAuthApiRef,
};

const lgTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#053b80',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: '#8c4351',
    },
    warning: {
      main: '#8f5e15',
    },
    info: {
      main: '#34548a',
    },
    success: {
      main: '#485e30',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    banner: {
      info: '#34548a',
      error: '#8c4351',
      text: '#343b58',
      link: '#565a6e',
    },
    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',
    navigation: {
      background: '#edf1f6',
      indicator: '#053b80',
      color: '#333',
      selectedColor: '#053b80',
      navItem: {
        hoverBackground: '#d9e8fa'
      },
      submenu: {
        background: '#d9e8fa'
      }
    },
  },
  defaultPageTheme: 'home',
  fontFamily: 'Sans Serif',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme(['#053b80', '#415d80'], shapes.wave),
    documentation: genPageTheme(['#053b80', '#415d80'], shapes.wave),
    tool: genPageTheme(['#053b80', '#415d80'], shapes.wave),
    service: genPageTheme(['#053b80', '#415d80'], shapes.wave),
    website: genPageTheme(['#053b80', '#415d80'], shapes.wave),
    library: genPageTheme(['#053b80', '#415d80'], shapes.wave),
    other: genPageTheme(['#053b80', '#415d80'], shapes.wave),
    app: genPageTheme(['#053b80', '#415d80'], shapes.wave),
    apis: genPageTheme(['#053b80', '#415d80'], shapes.wave),
  },
});


const app = createApp({
  apis,
  themes: [{
    id: 'lg-theme',
    title: 'Louwman Group Theme',
    variant: 'light',
    Provider: ({ children }) => (
      React.createElement(ThemeProvider, { theme: lgTheme,}
        , React.createElement(CssBaseline, null, children)
      )
    ),
  }],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
  },
  components: {
    SignInPage: props => (
      React.createElement(SignInPage, {
         ...props,
         auto: true,
         provider: microsoftProvider,
         align: "center",}
     )
     ),
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  React.createElement(FlatRoutes, null
    , React.createElement(Navigate, { key: "/", to: "catalog",} )
    , React.createElement(Route, { path: "/catalog", element: React.createElement(CatalogIndexPage, null ),} )
    , React.createElement(Route, {
      path: "/catalog/:namespace/:kind/:name",
      element: React.createElement(CatalogEntityPage, null ),}
    
      , entityPage
    )
    , React.createElement(Route, { path: "/docs", element: React.createElement(TechDocsIndexPage, null ),}
      , React.createElement(DefaultTechDocsHome, null )
    )
    , React.createElement(Route, {
      path: "/docs/:namespace/:kind/:name/*",
      element: React.createElement(TechDocsReaderPage, null ),}
    )
    , React.createElement(Route, { path: "/create", element: React.createElement(ScaffolderPage, null ),} )
    , React.createElement(Route, { path: "/api-docs", element: React.createElement(ApiExplorerPage, null ),} )
    , React.createElement(Route, {
      path: "/tech-radar",
      element: React.createElement(TechRadarPage, { width: 1500, height: 800,} ),}
    )
    , React.createElement(Route, { path: "/catalog-import", element: React.createElement(CatalogImportPage, null ),} )
    , React.createElement(Route, { path: "/search", element: React.createElement(SearchPage, null ),}
      , searchPage
    )
    , React.createElement(Route, { path: "/settings", element: React.createElement(UserSettingsPage, null ),} )
    , React.createElement(Route, { path: "/catalog-graph", element: React.createElement(CatalogGraphPage, null ),} )
    , React.createElement(Route, { path: "/lighthouse", element: React.createElement(LighthousePage, null ),} )
  )
);

const App = () => (
  React.createElement(AppProvider, null
    , React.createElement(AlertDisplay, null )
    , React.createElement(OAuthRequestDialog, null )
    , React.createElement(AppRouter, null
      , React.createElement(Root, null, routes)
    )
  )
);

export default App;
